<template>
	<div>
		<div class="title">{{info.title}}</div>
		<div class="content">{{info.content}}</div>
		<div class="info">مائاشى: {{info.money}}</div>
	</div>
</template>


<script>
	import {uchur_content} from '@/api/admin/login'
	export default {
		name:'vip',
		components: {
			
		},
		data(){
			return {
				info:{}
			}
		},
		methods: {
			homeUchurApi(){
				const id = this.$route.query.id;
					   uchur_content({id:id}).then(res=>{
						   console.log(res.data)
						   if(res.status==200){
							   this.info=res.data
						   }
					   }).catch(err=>{
			           console.log(err);
			       });
			}
		},
		mounted(){
			this.homeUchurApi()
		}
	}
</script>
<style >
	.title {
		overflow: hidden;
		font-size: 16px;
		height: 50px;
		line-height: 50px;
	}
	.content {
		line-height: 30px;
		padding: 20px;
		text-align: justify;
	}
</style>