import request from '@/api/request'


//微信登陆
export function index(params) {
	return request({ url: '/login/index',method: 'post',data: params})
}


export function uchur(params) {
	return request({ url: '/login/uchur',method: 'post',data: params})
}

export function uchur_content(params){
	return request({url:'/login/uchur_content',method:'post',data:params})
}

export function down(params){
	return request({url:'https://tamgha.gulum.vip/index/weli/down_sucai',method:'post',data:params})
}

export function b33591(url,params){
	return request({url:url,method:'post',data:params})
}

export function weli(url){
	return url
}

